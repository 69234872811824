@font-face {
    font-family: 'Aesthetic';
    src: local('Aesthetic'), url(../../assets/fonts/Aesthetic-Regular.woff) format('woff');
}

@font-face {
    font-family: 'Andale';
    src: local('Aesthetic'), url(../../assets/fonts/Andale.woff) format('woff');
}

.edene-wrapper {
    height: 1024px; /* Halved height */
    width: 1350px; /* Halved width */
    overflow: hidden;
}

.andale {
    font-family: Andale;
}

.edene-title {
    font-family: Aesthetic;
    color: white;
    font-size: 75px; /* Halved font size */
    text-align: center;
}

.edene-title-action {
    color: white;
    font-size: 17.5px; /* Halved font size */
    text-align: center;
    font-weight: 100;
    margin-top: 97px; /* Halved margin */
    font-family: Andale;
}

.edene-title-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.move-up {
    margin-top: -100px;
}

.edene-onboarding-title {
    top: 105px; /* Halved top position */
    position: absolute;
}

.edene-onboarding-container {
    justify-content: start;
}

.move-down {
    margin-top: 60px
}

.edene-slogan {
    margin-top: 40px; /* Halved top position */
    position: relative;
    z-index: 99;
}

.background {
    background-image: url("../../assets/images/bg2_alt.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 1024px; /* Halved height */
    width: 1380px; /* Halved width */
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
}

.background-garden {
    background-image: url("../../assets/images/bg2_alt.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 1024px; /* Halved height */
    width: 1380px; /* Halved width */
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
}

.flower-display {
    width: 100px; /* Halved width */
    height: 100px; /* Halved height */
}

.edene-action-circle-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.edene-action-circle {
    display: block;
    height: 245.5px; /* Halved height */
    width: 245.5px; /* Halved width */
    line-height: 245.5px; /* Halved line-height */
    border-radius: 140.5px; /* Halved border-radius */
    background-color: #7b955d59;
    color: white;
    text-align: center;
    font-size: 1em; /* Halved font size */
    cursor: pointer;
}

.edene-body-text {
    color: white;
    font-size: 15px; /* Halved font size */
    text-align: left;
}

.edene-onboarding-container {
    margin-left: 312px; /* Halved margin */
    margin-right: 312px; /* Halved margin */
    height: 400px; /* Halved height */
    position: absolute;
    z-index: 1;
    overflow: hidden;
}

.edene-action-button-container {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}


.center-override {
    justify-content: center;
}

.edene-action-button {
    color: white;
    font-size: 12.5px; /* Halved font size */
    border-radius: 35.5px; /* Halved border-radius */
    background-color: #7b955d59;
    position: relative;
    z-index: 1;
    width: auto;
    min-width: 50px;
    padding: 20px; /* Halved padding */
    cursor: pointer;
    text-align: center;
}

.heading2 {
    font-size: 17.5px; /* Halved font size */
}



/* QUESTIONS */

.edene-progress-map {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: auto;
    left: 100px;
    top: 150px;
    height: 700px;
    position: absolute;
    z-index: 1;
    justify-content: space-between;
    overflow: hidden;
    padding: 5px;
}

.edene-step {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.edene-step-icon {
    width: 50px;
    height: 50px;
    filter: blur(2px);
    margin-right: 20px;
}

.edene-step-icon.active {
    filter: blur(0px);
}

.edene-step-title {
    font-family: Aesthetic;
    font-size: 25px;
    color: white;
}

.edene-question-container {
    width: 700px;
    height: 500px;
    position: absolute;
    z-index: 1;
    left: 600px;
    top: 250px;
    overflow: hidden;
}

.edene-question-title {
    font-family: Andale;
    font-size: 20px;
    color: white;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
}

.edene-answer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    margin-left: 45px;
}


.edene-answer-identifier {
    font-family: Andale;
    font-size: 10px;
    font-weight: 100;
    color: white;
    border-radius: 140.5px; /* Halved border-radius */
    background-color: #7b955d59;
    line-height: 50px;
    height: 50px;
    width: 50px;
    text-align: center;
    cursor: pointer;
}

.edene-answer-title {
    font-family: Andale;
    font-size: 20px;
    font-weight: 100;
    color: white;
    margin-left: 25px;
}


.answer-identifier {
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: inline-block;
  }
  
  .answer-identifier::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0);
    transition: width 0.3s, height 0.3s, background-color 0.3s;
  }
  
  .answer-identifier.active::after {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .edene-flower-layer {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .edene-flower-container {
    position: fixed;
    z-index: 1;
    top: 170px;
    left: 360px;
    height: 500px; /* Halved height */
    width: 500px; /* Halved width */
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
  }
  
  svg .cls-1{
    stroke: white;
    stroke-width: 2px;
 }
 svg .cls-2{
    stroke: white;
    stroke-width: 2px;
 }
 svg .cls-3{
    stroke: white;
    stroke-width: 2px;
 }
 svg .cls-4{
    stroke: white;
    stroke-width: 2px;
 }
 svg .cls-5{
    stroke: white;
    stroke-width: 2px;
 }
 svg .cls-6{
    stroke: white;
    stroke-width: 2px;
 }

 .edene-menu {
    position: fixed;
    z-index: 11;
    top: 30px;
    left: 30px;
    display: flex;
    justify-content: space-between;
    width: 1286px;
 }

 .edene-pass {
    background-color: white;
    border-radius: 22px;
    width: 750px;
    height: 500px;
    margin-bottom: 150px;
 }

 .alternative {
    bottom: 200px;
 }

 .alternative-button {
    width: auto;
    display: inline-block;
  }

  .onboarding-data {
    color: black;
  }

  .questions-data {
    color: black;
  }

  .pass-actions {
    margin-top: 50px;
  }

  /* Define the styles for the confirmation dialog */
.confirmation-dialog {
    position: fixed;
    top: 0;
    left: 0;
    height: 1000px; /* Halved height */
    width: 1386px; /* Halved width */
    background-image: url("../../assets/images/bg2.png");
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .confirmation-content {
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    width: 80%;
  }
  
  .confirmation-title {
    font-family: Aesthetic;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .confirmation-message {
    font-family: Andale;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .confirmation-buttons {
    width: 200px;
    top: 600px;
    left: 580px;
    height: 50px;
  }
  
  /* Styles for the About component */
.edene-about-title {
    font-size: 48px;
    text-align: center;
    margin-top: -200px;
  }
  
  .edene-body-text h2 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .edene-body-text p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .learn-more {
    margin-left: 45px;
    margin-top: 40px;
    width: 100px;
  }

  .learn-more-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .learn-more-image {
    width: 70%;
  }
/* CSS for fade-in and fade-out animations */

