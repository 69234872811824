.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 500px;
    left: 420px;
    z-index: 1000;
  }
  
  .progress-bar {
    width: 500px;
    height: 15px;
    border-radius: 2px;
    overflow: hidden;
    appearance: none;
  }
  
  .progress-bar::-webkit-progress-bar {
    background-color: #55555500;
    border-radius: 2px;
    border: solid 2px white;
  }
  
  .progress-bar::-webkit-progress-value {
    background-color: rgb(255, 255, 255);
    border-radius: 2px;
  }
  
  .progress-text {
    margin-top: 10px;
    color: #fff;
    font-weight: bold;
  }
  