@font-face {
  font-family: 'Aesthetic';
  src: local('Aesthetic'), url(../../assets/fonts/Aesthetic-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Andale';
  src: local('Aesthetic'), url(../../assets/fonts/Andale.woff) format('woff');
}


.flower-carousel {
    display: flex;
    overflow-x: auto;  /* Enable horizontal scrolling */
    overflow-y: hidden;
    padding: 20px;
    align-items: flex-start;  /* Align items at the start of the cross axis */
    padding-left: 10px;
    padding-right: 10px;
    height: 320px;
    width: 1300px;
    margin-left: 22px;
    bottom: 410px;
    left: 0;
    position: relative;
  }
  
  .flower-wrapper {
    position: relative;  /* Create a new positioning context */
    width: 100px;  /* Adjust width as needed */
    height: 100px;  /* Adjust height as needed */
    margin-right: 280px;  /* Optional: add space between items */
    transform: scale(0.8);
  }
  
  .flower-wrapper > div {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(calc(var(--index) * 100%));
  }
  .flower-carousel {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.flower-carousel::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
  
.flower-code {
  text-align: center;
  width: 100%;
  margin-left: 99px;
  margin-top: 310px;
  font-family: Andale;
  font-size: 20px;
}

.latest-flower-container {
  width: 900px;
  height: 400px;
  position: fixed;
  top: 250px;
  left: 230px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.latest-flower-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Aesthetic;
  font-size: 40px;
}

.latest-flower-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Andale;
  font-size: 16px;
  text-align: right;
}

.latest-flower {
  position: fixed;  /* Create a new positioning context */
  width: 150px;  /* Adjust width as needed */
  height: 150px;  /* Adjust height as needed */
  transform: scale(1.3);
}

.latest-flower > div {
  position: fixed;
  top: 0;
  left: 220px;
  transform: translateX(calc(var(--index) * 100%));
}
.flower-count {
  position: absolute;
  top: 650px;
  left: 545px;
  color: white;
  font-family: Andale;

}