body {
  overflow-x: hidden;
  overflow: hidden;
}
@font-face {
  font-family: "Aesthetic";
  src: local("Aesthetic"),
    url(../../assets/fonts/Aesthetic-Regular.woff) format("woff");
}

@font-face {
  font-family: "Andale";
  src: local("Aesthetic"), url(../../assets/fonts/Andale.woff) format("woff");
}

input {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-bottom: solid 1px white;
  font-size: 40px;
  color: white;
  font-family: Andale;
  text-align: center;
}

textarea:focus,
input:focus {
  outline: none;
}

.center {
  text-align: center;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 0.5s forwards;
}

.fade-in-image {
  opacity: 0;
  animation: fadeInImage 0.5s forwards;
}

.fade-out-image {
  opacity: 1;
  animation: fadeOutImage 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInImage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutImage {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.transition-image-container {
  transform: translate(100px);
}

.centered-svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust height as needed */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    transform-origin: center;
  }
  50% {
    transform: scale(1.1);
    transform-origin: center;
  }
  100% {
    transform: scale(1);
    transform-origin: center;
  }
}

.pulsate {
  animation: pulse 2s infinite ease-in-out;
}


@page {
  size: auto;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
