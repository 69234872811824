.background-mobile {
    background-image: url("../../assets/images/phone_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh; /* Halved height */
    width: 100%; /* Halved width */
    position: fixed;
    overflow-x: hidden;
    top: 0;
    left: 0;
    z-index: -1;
}

.edene-mobile-wrapper {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;

}

.edene-about-mobile {
    width: 100%;
    overflow-x: hidden;
}

.edene-title-mobile {
    font-family: Aesthetic;
    font-size: 4em;
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.edene-body-mobile {
    font-family: Andale;
    width: auto;
    padding: 20px;
}

.edene-body-mobile-nopadding {
  font-family: Andale;
  max-width: 100vw;
  overflow: hidden;
}


.team-name-mobile {
    width: 100%;
    font-size: 36px;
    text-align: center;
    padding: 20px;
    font-family: Aesthetic;
}

.team-body-mobile {
    display: flex;
    justify-content: center;
    font-family: Andale;
    padding: 20px;
}

.team-container-mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .team-member-mobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
  }
  
  .mobile-img {
    width: 80%;
    padding: 10%;
  }
  

  .description-mobile {
    padding: 20px;
  }

  .team-subtitle-mobile {
    font-family: Andale;
    color: rgb(148, 148, 148);
  }


  @font-face {
    font-family: 'Aesthetic';
    src: local('Aesthetic'), url(../../assets/fonts/Aesthetic-Regular.woff) format('woff');
  }
  
  @font-face {
    font-family: 'Andale';
    src: local('Aesthetic'), url(../../assets/fonts/Andale.woff) format('woff');
  }
  
  
  .flower-carousel-mobile {
      display: flex;
      overflow-x: auto;  /* Enable horizontal scrolling */
      overflow-y: hidden;
      padding: 20px;
      align-items: flex-start;  /* Align items at the start of the cross axis */
      padding-left: 10px;
      padding-right: 10px;
      height: 300px;
      width: 100vw;
    }
    
    .flower-wrapper-mobile {
      position: relative;  /* Create a new positioning context */
      width: 100px;  /* Adjust width as needed */
      height: 100px;  /* Adjust height as needed */
      margin-right: 280px;  /* Optional: add space between items */
      transform: scale(0.8);

    }
    
    .flower-wrapper-mobile > div {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(calc(var(--index) * 100%));
    }
    .flower-carousel-mobile {
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
  }
  .flower-carousel-mobile::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }
    
  .flower-code-mobile {
    text-align: center;
    width: 100%;
    margin-left: 99px;
    margin-top: 310px;
    font-family: Andale;
    font-size: 20px;
  }
  
  .latest-flower-container-mobile {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .latest-flower-name-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Aesthetic;
    font-size: 40px;
  }
  
  .latest-flower-info-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Andale;
    font-size: 16px;
    text-align: right;
    flex-wrap: wrap;
    padding: 10px;
  }
  
  .latest-flower-mobile {  /* Create a new positioning context */
    width: auto;  /* Adjust width as needed */
    height: auto;  /* Adjust height as needed */
    transform: scale(1.3);
  }
  
  .latest-flower-mobile > div {
    position: fixed;
    top: -20px;
    left: 14%;
    transform: translateX(calc(var(--index) * 100%));
  }
  
  .flower-count-mobile {
    color: white;
    font-family: Andale;
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .flower-detail-mobile {
    width: 100vw;
    display: flex;
    justify-content: space-between;
  }

  .overview-title-mobile {
    padding: 20px;
    max-width: 90%;
    font-size: 48px;
  }

  .divider {
    width: 90%;
    margin-left: 5%;
    height: 2px;
    margin-bottom: 100px;
    background-color: white;
  }
  a {
    text-decoration: none;
    color: white;
  }

  .team-links-mobile {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-family: Andale
    ;
  }