

  .pass-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 350px;
    justify-content: space-between;
  }


  .full-width-button {
    animation: pulse2 2s infinite ease-in-out;
    width: 100%;
  }

  @keyframes pulse2 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .half-width-button {
    width: 33%;
    margin-top: 20px;
  }
  
  .pass-container {
    margin-top: -100px;
    width: 700px;
    max-width: 800px;
    background: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: black;
    height: 480px;
    font-family: Andale;
    overflow: hidden;
  }
  
  .pass-header {
    text-align: center;
  }
  
  .pass-header h1 {
    font-family: Aesthetic;
    font-size: 33px;
    margin: 0;
  }
  
  .pass-header p {
    font-family: Andale;
    font-size: 10px;
    margin: 5px 0 20px 0;
  }
  
  .pass-body {
    font-family: Andale;
    display: flex;
    justify-content: space-between;
  }
  
  .flower-image {
    width: 500px; /* Adjust size as needed */
    height: auto;
    border-right: dotted 1px black;
  }
  
  .flower-image svg {
    transform: scale(0.65);
    position: relative;
    left: -80px;
    top: -35px;
  }
  
  .pass-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    font-family: Andale;
    font-size: 30px;
    color: black;
    width: 180px;
  }
  
  .info {
    margin-bottom: 20px;
  }
  
  .label {
    font-family: Andale;
    display: block;
    font-size: 12px;
    color: #777;
  }
  
  .value {
    font-size: 20px;
    font-weight: bold;
  }

  .pass-header-title {
    font-family: Aesthetic;
    font-size: 33px;
    margin: 0;
  }
  
  .signature {
    margin-top: 40px;
  }
  
  .signature span {
    display: block;
    font-size: 12px;
    color: #777;
    border-top: 1px dotted #000;
    padding-top: 5px;
    margin-top: 20px;
  }
  
  .pass-footer {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 20px;
  }
  
  .footer-left,
  .footer-right {
    font-family: Andale;
    display: flex;
    align-items: center;
    color: black;
  }
  
  .flower-image svg .cls-1 {
    stroke: black;
    stroke-width: 4px;
  }
  .flower-image svg .cls-2 {
    stroke: black;
    stroke-width: 4px;
  }
  .flower-image svg .cls-3 {
    stroke: black;
    stroke-width: 4px;
  }
  .flower-image svg .cls-4 {
    stroke: black;
    stroke-width: 4px;
  }
  .flower-image svg .cls-5 {
    stroke: black;
    stroke-width: 4px;
  }
  .flower-image svg .cls-6 {
    stroke: black;
    stroke-width: 4px;
  }
  
  .alternative-button {
    margin-left: 5px;
    margin-right: 5px;
  }
  

  /* pass.css */

/* Other existing styles */

/* pass.css */

/* Other existing styles */

/* Styles specifically for printing */
.disabled {
  background-color: rgb(75, 75, 75);
}

@media print {
  html, body {
      height: 100%;
      overflow: hidden;
  }

  .value {
    font-size: 20px;
    font-weight: 500;
  }

  #edene-wrapper {
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
  }

  .pass-container {
      transform: rotate(90deg) scale(0.7);
      position: fixed;
      bottom: 505px;
      left: -200px;
      page-break-inside: avoid;
      page-break-before: auto;
      page-break-after: auto;
      overflow: visible; /* Ensure content is fully visible */
  }

  .flower-image svg {
      padding-top: 80px;
      margin-left: -120px;
      transform: scale(1.5);
  }

  .pass-actions,
  .edene-title-container:not(.passport) {
      display: none;
  }

  .edene-flower-container {
      position: fixed; /* Correct positioning for print */
      top: 30px;
      left: 190px;
      transform: scale(0.7);
  }
}
  

  