.team-description {
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    overflow: hidden;
  }
  
  .team-description .team-title {
    transition: opacity 0.5s ease-in-out;
  }
  
  .team-description .team-body {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .team-description.collapsed .team-body {
    opacity: 0;
    pointer-events: none;
  }
  
  .team-description.collapsed .team-title {
    opacity: 1;
  }
  
  .team-description.collapsed {
    max-height: 50px; /* Adjust this value to match the height of the title */
  }
  
  .team-description.expanded {
    max-height: 1000px; /* A large value to ensure full content is visible */
  }
  
  
  
  .team-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  
  .team-member {
    display: flex;
    gap: 30px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    width: 1000px;
    text-align: center;
    margin-left: 120px;
  }
  
  .team-member img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 10px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  
  .team-title {
    font-size: 16px;
    font-family: Andale, sans-serif;
    text-transform: uppercase;
  }
  
  .edene-about-container {
    width: 700px;
    font-family: Andale;
    margin-top: 50px;
    height: 1500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }

  .edene-about-information {
    width: 800px;
  }

  .edene-about-paragraph {
    margin-top: 50px;
  }

  .team-name {
    min-width: 200px;
    max-width: 200px;
    font-size: 35px;
    font-family: Aesthetic;
    text-align: left;
  }

  .team-title {
    font-size: 16px;
    font-family: Andale;
    text-align: left;
  }

  .team-body {
    width: 400px;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 16px;
    font-family: Andale;
    text-align: left;
  }

  .team-links {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 12px;
    font-family: Andale;
    text-align: left;
  }

  .about-wrapper {
    overflow: scroll;
    height: 1020px;
}

